<script setup>
import { ref, onMounted, computed } from 'vue';

const videoFile = ref(null);
const excelFile = ref(null);
const isUploading = ref(false);
const isCompleted = ref(false);
const progress = ref([0, 1]); // 默认值为 [0, 1]，避免除以零
const completedList = ref([]);
const selectedFiles = ref({});
const uploadProgress = ref(0); // 上传进度
const apiUrl = 'https://dev.liuhe-shenzhou.com/editor_api';
// const apiUrl = 'http://127.0.0.1:5000';

const handleVideoFileChange = (event) => {
  videoFile.value = event.target.files[0];
};

const handleExcelFileChange = (event) => {
  excelFile.value = event.target.files[0];
};

const handleSubmit = () => {
  const formData = new FormData();
  formData.append('videoFile', videoFile.value);
  formData.append('excelFile', excelFile.value);
  console.log('formData:', formData.get('videoFile'), formData.get('excelFile'));

  isUploading.value = true;
  isCompleted.value = false;
  progress.value = [0, 1]; // 重置进度

  const xhr = new XMLHttpRequest();
  xhr.open('POST', `${apiUrl}/video/clip`, true);

  // 监听上传进度事件
  xhr.upload.addEventListener('progress', (event) => {
    if (event.lengthComputable) {
      uploadProgress.value = (event.loaded / event.total) * 100;
      console.log(`上传进度: ${uploadProgress.value}%`);
    }
  });

  xhr.onload = () => {
    if (xhr.status === 200) {
      console.log('文件上传成功');
      updateProgress();
    } else {
      console.error('文件上传失败');
      isUploading.value = false;
    }
  };

  xhr.onerror = () => {
    console.error('文件上传错误');
    isUploading.value = false;
  };

  xhr.send(formData);
};

const updateProgress = async () => {
  try {
    const response = await fetch(`${apiUrl}/video/progress`);
    const data = await response.json();
    console.log('data:', data);

    progress.value = data.progress;

    if (progress.value[0] < progress.value[1]) {
      isUploading.value = true;
      isCompleted.value = false;
      setTimeout(updateProgress, 5000);
    } else {
      isUploading.value = false;
      isCompleted.value = true;
      fetchCompletedList();
    }
  } catch (error) {
    isUploading.value = false;
    console.error('更新进度失败', error);
  }
};

const fetchCompletedList = async () => {
  try {
    const response = await fetch(`${apiUrl}/video/table/query/url_oss`);
    const data = await response.json();
    completedList.value = Array.isArray(data) ? data : [];
    console.log('completedList:', completedList.value);
  } catch (error) {
    console.error('获取已完成列表失败', error);
    completedList.value = []; // 确保在错误情况下 completedList 也是一个数组
  }
};

const groupedCompletedList = computed(() => {
  if (!Array.isArray(completedList.value)) {
    return {};
  }

  // 反向排序 completedList，以便新存储的记录在前
  const sortedList = [...completedList.value].reverse(); // 创建副本并反向排序

  return sortedList.reduce((acc, item) => {
    if (!acc[item.ori_video_name]) {
      acc[item.ori_video_name] = [];
    }
    acc[item.ori_video_name].push(item);
    return acc;
  }, {});
});

const toggleSelectAll = (oriVideo, event) => {
  const items = groupedCompletedList.value[oriVideo];
  items.forEach(item => {
    selectedFiles.value[`${oriVideo}-${item.id}`] = event.target.checked;
  });
};

const downloadSelected = async (oriVideo) => {
  const items = groupedCompletedList.value[oriVideo];
  const filesToDownload = items.filter(item => selectedFiles.value[`${oriVideo}-${item.id}`]);
  for (const file of filesToDownload) {
    const link = document.createElement('a');
    link.href = file.url;
    link.href = getSecureUrl(file.url)
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    await new Promise(resolve => setTimeout(resolve, 300)); // 延迟 300 毫秒
  }
};

const getSecureUrl = (url) => {
  return url.replace(/^http:\/\//i, 'https://');
};

onMounted(() => {
  fetchCompletedList();
  updateProgress();
});
</script>

<template>
  <div>
    <h1>控制面板</h1>
    <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
      <label for="videoFile">视频文件:</label>
      <input type="file" id="videoFile" @change="handleVideoFileChange" accept="video/*" required><br><br>
      <label for="excelFile">Excel标记文件:</label>
      <input type="file" id="excelFile" @change="handleExcelFileChange" accept=".xlsx" required><br><br>
      <button type="submit">开始</button>
    </form>
    <div v-if="isUploading || isCompleted">
      <div class="progress-container">
        <div class="progress-bar">
          <div class="progress" :style="{ width: uploadProgress + '%' }">
          </div>
          <span class="progress-text">{{ isCompleted ? '上传完成！' : '上传中……' }}</span>
        </div>
        <p class="progress-info">{{ uploadProgress.toFixed(2) }}%</p>
      </div>
      <div class="progress-container">
        <div class="progress-bar">
          <div class="progress" :style="{ width: (progress[0] / progress[1]) * 100 + '%' }">
          </div>
          <span class="progress-text">{{ isCompleted ? '处理完成！' : '处理中……' }}</span>
        </div>
        <p class="progress-info">{{ progress[0] }} / {{ progress[1] }}</p>
      </div>
    </div>
    <div>
      <h3>输出列表</h3>
      <div v-for="(items, oriVideo) in groupedCompletedList" :key="oriVideo">
        <div class="oriVideo-header">
          <h3>{{ oriVideo }}</h3>
          <input type="checkbox" @change="toggleSelectAll(oriVideo, $event)" /> 全选
          <button @click="downloadSelected(oriVideo)">下载选中</button>
        </div>
        <ul>
          <li v-for="item in items" :key="item.id">
            <input type="checkbox" v-model="selectedFiles[`${oriVideo}-${item.id}`]" />
            <a :href="getSecureUrl(item.url)" target="_blank">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

label {
  text-align: left;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

input[type="file"] {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  width: 150px;
  height: 40px;
  margin-top: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

.progress-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  width: 300px;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 10px;
  position: relative;
}

.progress {
  height: 20px;
  background-color: #4caf50;
  width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 12px;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-info {
  margin: 0;
  font-weight: bold;
  color: #333;
}

h3 {
  text-align: left;
  color: #4caf50;
  margin-top: 20px;
}

ul {
  padding-left: 0;
  list-style-type: none;
}

li {
  text-align: left;
  margin-bottom: 5px;
}

a {
  color: #4caf50;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #45a049;
}

.oriVideo-header {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>